import React from 'react' 
function BreadCrumbs(props){
    return (        
        <div>
            <section className="w3l-about-breadcrumb">
                <div className="breadcrumb-bg breadcrumb-bg-about py-5">
                    <div className="container py-lg-5 py-md-3">
                        <h2 className="title">{props.title}</h2>
                    </div>
                </div>
            </section>
            <section className="w3l-breadcrumb">
                <div className="container">
                    <ul className="breadcrumbs-custom-path">
                        <li><a href="/">{props.page}</a></li>
                        <li className="active"><span className="fa fa-arrow-right mx-2" aria-hidden="true"></span> {props.title}</li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
export default BreadCrumbs



