import React from 'react' 
import Home from './pages/home' 
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Header from './components/header'
import Footer from './components/footer'
import About from './pages/about' 
import Services from './pages/services' 
import Contact from './pages/contact' 
import Gallery from './pages/gallery'
import OurProducts from './pages/ourproducts'

function App(){
  return(
    <div>
    <Header/>
    <BrowserRouter> 
    <Routes>
      <Route path='/' element={<Home/>}/>      
      <Route path='/about' element={<About/>}/>
      <Route path='/our-products' element={<OurProducts/>}/>      
      <Route path='/services' element={<Services/>}/>
      <Route path='/gallery' element={<Gallery/>}/>    
      <Route path='/contact' element={<Contact/>}/>  
    </Routes>
    </BrowserRouter>  
    <Footer/>
    </div>
    
  )
}
export default App