import React from 'react';
import LazyLoad from 'react-lazyload';

const ImageGallery = () => {
  // Assuming all images are in the public/images folder
  const imageContext = require.context('/public/assets/images/gallery', false, /\.(png|jpe?g|svg|gif)$/);

  // Get all image paths
  const imagePaths = imageContext.keys().map(imageContext);
  const imageRows = [];
  for (let i = 0; i < imagePaths.length; i += 4) {
    imageRows.push(imagePaths.slice(i, i + 4));
  }
  return (
<div className="container mt-4">
      {imageRows.map((row, rowIndex) => (
        <div className="row" key={rowIndex}>
          {row.map((path, index) => (
            
            <a href={path} key={index} className="col-md-3 mb-4" data-toggle="lightbox" data-gallery="gallery">
              <LazyLoad height={200} offset={100} once>
              <img src={path} alt={`image-${index}`} className="img-fluid rounded" />
              </LazyLoad>
            </a>
            
          ))}
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;

