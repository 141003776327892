import React from 'react' 
import BreadCrumbs from '../components/breadcrumbs'
function About(){
    return (
        <div>
        <BreadCrumbs page="Home" title="About Us"/>
        
        <section className="w3l-services-6-main">
            <div className="services-6 py-5">
                <div className="container py-md-3">
                    <div className="row serv_sec_info">
                        <div className="col-lg-6 banner_bottom_grid help">
                            <img src="assets/images/about.jpg" alt=" " className="img-fluid radius-image"/>
                        </div>
                        <div className="col-lg-6 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4">
                            <h4><a className="link-hny" href="#">
                                    We Present the idea with impressive and creative details</a></h4>
                            <p>Welcome to Earthcon India, where architecture is more than just a profession—it’s a passion. Founded with a vision to create spaces that inspire, Earthcon India has established itself as a premier architectural solution firm based in Kolkata, with our head office in Mumbai.</p>
                            
                        </div>
                        <div className="col-lg-12 banner_bottom_left pl-lg-4 mt-md-5 mt-10">
                            <h4><a className="link-hny" href="#">
                                    Who We Are</a></h4>
                            <p>Earthcon India is a collective of creative minds dedicated to transforming environments through thoughtful design. Our team of architects, designers, and planners brings a wealth of experience and a deep understanding of the industry to every project. We specialize in a broad spectrum of services, including architecture, landscape design, interior design, and historic preservation.</p>
                            
                        </div>
                        <div className="col-lg-6 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4">
                            <h4><a className="link-hny" href="#">
                                    Our Vision</a></h4>
                            <p>Welcome to Earthcon India, where architecture is more than just a profession—it’s a passion. Founded with a vision to create spaces that inspire, Earthcon India has established itself as a premier architectural solution firm based in Kolkata, with our head office in Mumbai.</p>
                            
                        </div>
                        <div className="col-lg-6 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4">
                            <h4><a className="link-hny" href="#">
                                    Our Mission</a></h4>
                            <p>Our mission is to design spaces that are not only functional and sustainable but also beautiful and timeless. We strive to create environments that resonate with the people who inhabit them, blending aesthetics with practicality in every project.</p>
                            
                        </div>
                    </div>
                    <div className="row features-w3pvt-main" id="features">
                        <div className="col-lg-4 col-md-6 feature-gird">
                            <div className="row features-hny-inner-gd">
                                <div className="col-md-3 col-2 featured_grid_left">
                                    <div className="icon_left_grid">
                                        <span className="fa fa-bath" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0">
                                    <h4><a className="link-hny" href="#url">Custom Furniture </a></h4>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 feature-gird">
                            <div className="row features-hny-inner-gd">
                                <div className="col-md-3 col-2 featured_grid_left">
                                    <div className="icon_left_grid">
                                        <span className="fa fa-lightbulb-o" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0">
                                    <h4><a className="link-hny" href="#url">Lighting Design </a></h4>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 feature-gird">
                            <div className="row features-hny-inner-gd">
                                <div className="col-md-3 col-2 featured_grid_left">
                                    <div className="icon_left_grid">
                                        <span className="fa fa-shower" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div className="col-md-9 col-10 featured_grid_right_info pl-lg-0">
                                    <h4><a className="link-hny" href="#url">Furniture Sourcing </a></h4>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section className="content-with-photo-16 py-5">
            
            <div className="content-with-photo-16-main py-lg-5 py-sm-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 column">
                            <div className="content-with-photo-16-inf">
                                <h3>Our Values </h3>
                                <p>Aliquam blandit vel sapien eget aliquam. Phasellus eget volutpat libero. Suspendisse
                                    eleifend nibh quis dui placerat fermentum.n molestie nulla felis, vitae feugiat justo
                                    pulvinar et. </p>
                                <ul className="content-photo-list">
                                    <li>
                                        <span>Innovation: We believe in pushing the boundaries of design to create unique and inspiring spaces.</span>
                                    </li>
                                    <li>
                                        <span>Sustainability: Our commitment to the environment drives us to incorporate eco-friendly practices in our designs.</span>
                                    </li>
                                    <li>
                                        <span>Quality: From concept to completion, we maintain the highest standards of quality in every aspect of our work.</span>
                                    </li>
                                    <li>
                                        <span>Integrity: We value transparency, honesty, and a strong work ethic in all our professional relationships.</span>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                        <div className="col-lg-6 column mt-lg-0 mt-md-5 mt-4">
                            <div className="companies-img">
                                <img src="assets/images/about1.jpg" className="img-fluid radius-image" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-with-photo-16-main py-lg-5 py-sm-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 column mt-lg-0 mt-md-5 mt-4">
                            <div className="companies-img">
                                <img src="assets/images/about1.jpg" className="img-fluid radius-image" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 column">
                            <div className="content-with-photo-16-inf">
                                <h3>Why Choose Us</h3>
                                <p>Choosing Earthcon India means partnering with a team that is dedicated to turning your vision into reality. We understand that every project is unique, and we take pride in offering personalized solutions that cater to the specific needs and preferences of our clients.</p>                                
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        
        </div>
    )
}
export default About
