import React from 'react' 
import BreadCrumbs from '../components/breadcrumbs'
import ProductCard from '../components/productCard'
function OurProducts(){
    return (
        <div>
            <BreadCrumbs page="Home" title="Our Products"/>
            
            <section className="w3l-contact-2 py-5">
                <div className="contact-infubd section-gap py-lg-5 py-md-4">
                    <div className="container">
                    <ProductCard />
                        
                    </div>
                </div>
            </section>
        </div>
    )
}
export default OurProducts
