import React from 'react' 
import BreadCrumbs from '../components/breadcrumbs'
function Services(){
    return (
        <div>
            <BreadCrumbs page="Home" title="Services"/>
            
            <section id="services" className="services py-5">
                <div className="container py-lg-5 py-md-4">
                    <h3 className="title-big">Our Services</h3>
                <div className="row text-center img-grids pt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="ser-bg1">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon1.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Commercial Architecture</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-4">
                    <div className="ser-bg2">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon2.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Institutional Architecture</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4">
                    <div className="ser-bg3">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon3.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Parks and Recreational Areas</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="row text-center img-grids pt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="ser-bg1">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon1.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Public Infrastructure</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-4">
                    <div className="ser-bg2">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon2.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Residential Spaces</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4">
                    <div className="ser-bg3">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon3.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Heritage Restoration</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    
                </div>
                <div className="row text-center img-grids pt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="ser-bg1">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon1.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Sustainable Landscapes</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-md-0 mt-4">
                    <div className="ser-bg2">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon2.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Space Planning</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-lg-0 mt-4">
                    <div className="ser-bg3">
                        <div className="p-md-5 p-3">
                            <img src="assets/images/icon3.png" alt="" className="img-fluid" />
                            <h4><a href="#url">Furniture and Décor</a></h4>
                            
                        </div>
                    </div>
                    </div>
                    
                </div>
                </div>
            </section>
            
            

            
            <section className="grids-1 py-5">
                <div className="grids py-lg-5 py-md-4">
                    <div className="container">
                        <h3 className="title-big">What we do</h3>
                        <div className="row text-center grids-gap">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w1.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Interior Design</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-sm-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w2.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Architecture design</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-md-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w3.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Floor Plan</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w4.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Plans and Projects</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            
            <section className="w3l-covers-1">
                <div className="cover">
                    <div className="container">	
                        <div className="cover-content">
                            <h3>20% on New Collections. Get your discount today!</h3>
                            <a href="#Learn" className="read-more btn btn-style btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
        </section>
        
        </div>
    )
}
export default Services
