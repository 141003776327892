import React from 'react' 
const products = [
    {
      id: 1,
      name: 'EARTHCON Realty',
      description: 'This is a description for Product 1.',
      price: '$29.99',
      image: '/assets/images/products/realty.jpg',
    },
    {
      id: 2,
      name: 'EARTHCON Construction',
      description: 'This is a description for Product 2.',
      price: '$39.99',
      image: 'assets/images/products/construction.jpg',
    },
    {
      id: 3,
      name: 'EARTHCON Architect firm',
      description: 'This is a description for Product 3.',
      price: '$49.99',
      image: 'assets/images/products/architecture.jpg',
    },
    {
      id: 4,
      name: 'EARTHCON TMT Bar',
      description: 'This is a description for Product 4.',
      price: '$59.99',
      image: 'assets/images/products/tmt.jpg',
    },
    {
      id: 5,
      name: 'EARTHCON Cement',
      description: 'This is a description for Product 5.',
      price: '$69.99',
      image: 'assets/images/products/cement.jpg',
    },
    {
      id: 6,
      name: 'EARTHCON TILES',
      description: 'This is a description for Product 6.',
      price: '$79.99',
      image: 'assets/images/products/tiles.jpg',
    },
    {
      id: 7,
      name: 'EARTHCON MARBLE',
      description: 'This is a description for Product 7.',
      price: '$89.99',
      image: 'assets/images/products/marble.jpg',
    },
    {
      id: 8,
      name: 'EARTHCON PLY',
      description: 'This is a description for Product 8.',
      price: '$99.99',
      image: 'assets/images/products/plywood.jpg',
    },
    {
      id: 9,
      name: 'EARTHCON Ceramics',
      description: 'This is a description for Product 9.',
      price: '$109.99',
      image: 'assets/images/products/ceramics.jpg',
    },
    {
      id: 10,
      name: 'EARTHCON Epoxy Grout',
      description: 'This is a description for Product 10.',
      price: '$119.99',
      image: 'assets/images/products/epoxy.jpg',
    },
  ];
  
  const Product = ({ product }) => {
    return (
      <div className="card h-100">
        <img src={product.image} className="card-img-top img-fluid" alt={product.name} />
        <div className="card-body">
          <h5 className="card-title">{product.name}</h5>
          {/* <p className="card-text">{product.description}</p> */}
          {/* <p className="card-text text-primary">{product.price}</p> */}
        </div>
      </div>
    );
  };
  const ProductCard = () => {
    return (
      <div className="container mt-4">
        <div className="row">
          {products.map(product => (
            <div key={product.id} className="col-12 col-sm-6 col-md-3 col-lg-3 mb-4">
              <Product product={product} />
            </div>
          ))}
        </div>
      </div>
    );
  };
// function ProductCard(){
//     return (
//         <div className="card" style= {{ width: '18rem' }}>
//             <img src="/assets/images/s2.jpg" className="card-img-top" alt="singleproduct"/>
//             <div className="card-body">
//                 <h5 className="card-title">Card title</h5>
//                 <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
//                 <a href="#" className="btn btn-primary">Go somewhere</a>
//             </div>
//         </div>
//     )
// }
export default ProductCard
