import React from 'react' 
import BreadCrumbs from '../components/breadcrumbs'
import ImageGallery from '../components/imageGallery'
function Gallery(){
    return (
        <div>
            <BreadCrumbs page="Home" title="Gallery"/>
            
            <section className="w3l-contact-2 py-5">
                <div className="contact-infubd section-gap py-lg-5 py-md-4">
                    <div className="container">
                    <ImageGallery />
                        
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Gallery
