import React from 'react' 
function Home(){
    return (
        <div>
        <section className="w3l-main-slider">            
            <div className="companies20-content">
                <div className="companies-wrapper">
                <div className="owl-one owl-carousel owl-theme">
                    <div className="item">
                    <li>
                        <div className="slider-info banner-view">
                        <div className="banner-info container">
                            <h5 className="banner-text">Interior Design</h5>
                            <h3 className="banner-text">Where Creativity Meets Craftsmanship</h3>
                            <p>At Earthcon India, we believe in the power of thoughtful design and meticulous execution to create spaces that are both functional and beautiful.</p>
                            <a href="/about" className="btn btn-primary btn-style">Explore More</a>
                            <a href="/contact" className="btn transparent-btn btn-style ml-2">Contact Us</a>
                        </div>
                        </div>
                    </li>
                    </div>
                    <div className="item">
                    <li>
                        <div className="slider-info  banner-view banner-top1">
                        <div className="banner-info container">
                            <h5 className="banner-text">Home Decoration</h5>
                            <h3 className="banner-text">Building Dreams, One Space at a Time</h3>
                            <p>We work closely with our clients to transform their visions into reality, crafting spaces that inspire and endure for generations.</p>
                            <a href="/about" className="btn btn-primary btn-style">Explore More</a>
                            <a href="/contact" className="btn transparent-btn btn-style ml-2">Contact Us</a>
                        </div>
                        </div>
                    </li>
                    </div>
                    <div className="item">
                    <li>
                        <div className="slider-info banner-view banner-top2">
                        <div className="banner-info container">
                            <h5 className="banner-text">Historic Preservation</h5>
                            <h3 className="banner-text">Designing Tomorrow, Preserving Yesterday</h3>
                            <p>Our approach combines innovative design with a deep respect for history, ensuring that every project honors the past while embracing the future.</p>
                            <a href="/about" className="btn btn-primary btn-style">Explore More</a>
                            <a href="/contact" className="btn transparent-btn btn-style ml-2">Contact Us</a>
                        </div>
                        </div>
                    </li>
                    </div>
                </div>
                </div>
            </div>            
            </section>
            <section className="w3l-about" id="about">
                <div className="new-block py-5">
                    <div className="container py-lg-5 py-md-4">
                    <div className="row middle-section">
                        <div className="col-lg-6 section-width align-self">
                            <h5>25 years of experience</h5>
                        <h2>A Small efficient Interior design Team love to meet and work with you.</h2>
                            <p className="mt-4 pb-3">At Earthcon India, we blend creativity with precision to craft spaces that resonate with elegance and functionality. As a premier architectural solution firm, with our roots in Kolkata and our head office in Mumbai, we bring a deep understanding of design and heritage to every project we undertake.</p>
                            <a href="/about" className="btn btn-outline-primary btn-outline-style mt-4"> Know more about us</a>
                        </div>
                        <div className="col-lg-6 history-info mt-lg-0 mt-5 pt-lg-0 pt-md-4">
                        <div className="position-relative img-border">
                            <img src="assets/images/about.jpg" className="img-fluid video-popup-image" alt="video-popup"/>
                            
                            <a href="#small-dialog" className="popup-with-zoom-anim play-view text-center position-absolute">
                            <span className="video-play-icon">
                                <span className="fa fa-play"></span>
                            </span>
                            </a>

                            
                            <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                            <iframe src="https://www.youtube.com/embed/1wEKS6nTsS0" allowfullscreen></iframe>
                            </div>
                        </div>
                        </div>

                        
                        <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                        <iframe src="https://www.youtube.com/embed/Z_KspIX1oXU" allowfullscreen></iframe>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            
            <section id="services" className="home-services py-5">
            <div className="container py-lg-5 py-md-4">
                <h3 className="title-big">Our Services</h3>
                <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-pencil-square-o"></span>
                    </div>
                    <h4 className="number">01</h4>
                    <h4><a href="#url">Plans and Projects</a></h4>
                    <p>At Earthcon India, our approach to every project is guided by meticulous planning and a commitment to excellence. We believe that great design begins with a clear vision and is realized through thoughtful execution.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-shower"></span>
                    </div>
                    <h4 className="number">02</h4>
                    <h4><a href="#url">Interior Decor</a></h4>
                    <p>At Earthcon India, interior décor is about more than just aesthetics; it’s about creating spaces that tell a story, reflect individuality, and enhance the quality of life. Our approach to interior décor is holistic, ensuring that every element within a space contributes to a harmonious and functional environment.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-bed"></span>
                    </div>
                    <h4 className="number">03</h4>
                    <h4><a href="#url">Home Decoration</a></h4>
                    <p>At Earthcon India, home decoration is an art form that combines style, comfort, and functionality. We believe that every home should be a reflection of its owner’s personality, creating an environment that is both beautiful and welcoming.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-home"></span>
                    </div>
                    <h4 className="number">04</h4>
                    <h4><a href="#url">Home Furniture</a></h4>
                    <p>At Earthcon India, we understand that furniture is the heart of home décor. Our approach to home furniture is focused on blending functionality with elegance, ensuring that each piece adds value and style to your living space.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-bath"></span>
                    </div>
                    <h4 className="number">05</h4>
                    <h4><a href="#url">Architecture Design</a></h4>
                    <p>At Earthcon India, architecture design is about more than just constructing buildings; it's about creating spaces that inspire and endure. Our designs are a fusion of innovation, functionality, and aesthetic appeal, tailored to meet the unique needs of each project.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="box-wrap">
                    <div className="icon">
                        <span className="fa fa-picture-o"></span>
                    </div>
                    <h4 className="number">06</h4>
                    <h4><a href="#url">Flooring Interior</a></h4>
                    <p>At Earthcon India, we believe that flooring is the foundation of any interior space, setting the tone for the entire design. Our approach to flooring interior combines style, durability, and functionality to create surfaces that enhance the beauty and comfort of your home.</p>
                        {/* <a href="#read" className="read">Read more</a> */}
                    </div>
                </div>
                </div>
            </div>
            </section>
            
            
            <section className="grids-1 py-5">
                <div className="grids py-lg-5 py-md-4">
                    <div className="container">
                        <h3 className="title-big">What we do</h3>
                        <div className="row text-center grids-gap">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w1.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Interior Design</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-sm-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w2.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Architecture design</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-md-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w3.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Landscape</a></h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-4">
                                <div className="column">
                                    <a href="#single"><img src="assets/images/w4.jpg" alt="" className="img-responsive" /></a>
                                    <h4><a href="#single">Historic Preservation</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="w3l-testimonials">
                
                <div className="testimonials py-5">
                    <div className="container py-lg-3">
                            <h3 className="title-big">Our Projects</h3>
                        <div className="row">
                            <div className="col-lg-12 mx-auto">
                                <div className="owl-testimonial owl-carousel owl-theme text-center ">
                                    <div className="item">
                                        <div className="slider-info banner-view mt-lg-4 mt-3">
                                            <div className="slider-img-info">
                                                <h3>Interior work Furniture</h3>
                                                <div className="message">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id
                                                    accusantium officia quod quasi Harum error
                                                    quibusdam tenetur.</div>
                                                <a href="#url" className="btn btn-style btn-primary mt-4">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider-info banner-view banner-top1 mt-lg-4 mt-3">
                                            <div className="slider-img-info">
                                                <h3>Interior work Furniture</h3>
                                                <div className="message">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id
                                                    accusantium officia quod quasi Harum error
                                                    quibusdam tenetur.</div>
                                                <a href="#url" className="btn btn-style btn-primary mt-4">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider-info banner-view banner-top2 mt-lg-4 mt-3">
                                            <div className="slider-img-info">
                                                <h3>Interior work Furniture</h3>
                                                <div className="message">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id
                                                    accusantium officia quod quasi Harum error
                                                    quibusdam tenetur.</div>
                                                <a href="#url" className="btn btn-style btn-primary mt-4">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider-info banner-view banner-top3 mt-lg-4 mt-3">
                                            <div className="slider-img-info">
                                                <h3>Interior work Furniture</h3>
                                                <div className="message">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit id
                                                    accusantium officia quod quasi Harum error
                                                    quibusdam tenetur.</div>
                                                <a href="#url" className="btn btn-style btn-primary mt-4">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            
            <section className="w3l-stats">
                <div className="main-w3 py-5" id="stats">
                    <div className="container py-lg-3">
                        <h3 className="title-big">Infographic</h3>
                        <div className="row main-cont-wthree-fea">
                            <div className="col-lg-3 col-6 mt-4">
                                <div className="grids-speci1">
                                    <span className="fa fa-building" aria-hidden="true"></span>
                                    <h3 className="title-spe">25</h3>
                                    <p>Years Experience</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mt-4">
                                <div className="grids-speci1">
                                    <span className="fa fa-file-text" aria-hidden="true"></span>
                                    <h3 className="title-spe">250+</h3>
                                    <p> Projects Taken</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mt-sm-4 mt-3">
                                <div className="grids-speci1">
                                    <span className="fa fa-trophy" aria-hidden="true"></span>
                                    <h3 className="title-spe">120</h3>
                                    <p>Awards Own</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mt-sm-4 mt-3">
                                <div className="grids-speci1">
                                    <span className="fa fa-twitter" aria-hidden="true"></span>
                                    <h3 className="title-spe">18k</h3>
                                    <p>Twitter Followers</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}
export default Home
