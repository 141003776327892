import React from 'react' 
import BreadCrumbs from '../components/breadcrumbs'
function Contact(){
    return (
        <div>
            <BreadCrumbs page="Home" title="Contact Us"/>
            
            <section className="w3l-contact-2 py-5">
                <div className="contact-infubd section-gap py-lg-5 py-md-4">
                    <div className="container">
                        <div className="contact-grids d-grid">
                            <div className="contact-left">
                                <h4>Leave us a Message</h4>
                                <h6>For more info or inquiry about our products project, and pricing please feel free to get in
                                    touch with
                                    us.</h6>
                                <div className="hours">
                                    <h6 className="info mt-3">Email:</h6>
                                    <p> <a href="mailto:admin@earthconindia.in">
                                        admin@earthconindia.in</a></p>
                                    <h6 className="info mt-3">Head Office:</h6>
                                    <p> Inizio Business Center, Cardinal Gracious Road, Chakala, andheri (East), Mumbai-400 059.</p>
                                    <h6 className="info mt-3">Corporate Office:</h6>
                                    <p> 68/A Jodhpur park, Kolkata- 700 068</p>
                                    <h6 className="info mt-3">Contact:</h6>
                                    <p className="margin-top"><a href="tel:0332423-6067">033 2423-6067</a></p>
                                    <p className="margin-top"><a href="tel:+919073678901">+91-9073678901</a></p>
                                    <p className="margin-top"><a href="tel:+919073678902">+91-9073678902</a></p>
                                    <p className="margin-top"><a href="tel:+919073678903">+91-9073678903</a></p>
                                    <p className="margin-top"><a href="tel:+919073678904">+91-9073678904</a></p>
                                </div>
                            </div>
                            <div className="contact-right">
                                <form action="https://sendmail.w3layouts.com/submitForm" method="post" className="signin-form">
                                    <div className="input-grids">
                                        <input type="text" name="w3lName" id="w3lName" placeholder="Your Name*"
                                            className="contact-input" />
                                        <input type="email" name="w3lSender" id="w3lSender" placeholder="Your Email*"
                                            className="contact-input" required="" />
                                        <input type="text" name="w3lSubect" id="w3lSubect" placeholder="Subject*"
                                            className="contact-input" />
                                    </div>
                                    <div className="form-input">
                                        <textarea name="w3lMessage" id="w3lMessage" placeholder="Type your message here*"
                                            required=""></textarea>
                                    </div>
                                    <button className="btn btn-style btn-primary submit">Send Message</button>
                                </form>
                            </div>

                        </div>
                    </div>
                    </div>
            </section>
            
            <div className="map-iframe">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.3817765050863085!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C+UK!5e0!3m2!1sen!2spl!4v1562654563739!5m2!1sen!2spl"
                    width="100%" height="400" frameborder="0" style= {{ border: '0px', pointerEvents: 'none' }}
                    allowfullscreen=""></iframe>
            </div>            
        
        </div>
    )
}
export default Contact
