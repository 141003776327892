import React from 'react' 
function Footer(){
    return (
        <section className="w3l-footer-29-main">
        <div className="footer-29 py-5">
          <div className="container py-lg-4">
            <div className="row footer-top-29">
              <div className="footer-list-29 col-lg-4">
                <h6 className="footer-title-29">About Company</h6>
                <p>At Earthcon India, we blend creativity with precision to craft spaces that resonate with elegance and functionality.</p>
                <div className="main-social-footer-29 mt-4">
                  <a href="#facebook" className="facebook"><span className="fa fa-facebook"></span></a>
                  <a href="#twitter" className="twitter"><span className="fa fa-twitter"></span></a>
                  <a href="#instagram" className="instagram"><span className="fa fa-instagram"></span></a>
                  <a href="#linkedin" className="linkedin"><span className="fa fa-linkedin"></span></a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Contact Us</h6>
                <ul>
                  <li>
                    <p><span className="fa fa-map-marker"></span> Inizio Business Center, Cardinal Gracious Road, Chakala, andheri (East), Mumbai-400 059.</p>
                  </li>
                  <li><a href="tel:+919073678902"><span className="fa fa-phone"></span> +91-9073678902</a></li>
                  <li><a href="mailto:admin@earthconindia.in" className="mail"><span className="fa fa-envelope-open-o"></span>
                      admini@earthconindia.in</a></li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">
      
                <ul>
                  <h6 className="footer-title-29">Useful Links</h6>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">About Interiors</a></li>
                  <li><a href="/services">Services</a></li>
                  <li><a href="/contact">Contact us</a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Latest from blog</h6>
                <div className="footer-post mb-4">
                  <a href="#blog-single">Helping you and your house become better acquainted.</a>
                  <p className="small"><span className="fa fa-clock-o"></span> March 9, 2020</p>
                </div>
                <div className="footer-post">
                  <a href="#blog-single">Creating quality urban lifestyles..</a>
                  <p className="small"><span className="fa fa-clock-o"></span> March 9, 2020</p>
                </div>
      
              </div>
              <div className="col-lg-12 footer-list-29 footer-4 mt-5">
                <div className="column1 align-self">
                  <h6 className="footer-title-29 mb-1">Subscribe to our Newsletter </h6>
                  <p>Enter your email and receive the latest news from us.</p>
                </div>
                <div className="column1">
                  <form action="#" className="subscribe" method="post">
                    <input type="email" name="email" placeholder="Your Email Address" required=""/>
                    <button><span className="fa fa-envelope-o"></span></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button onclick="topFunction()" id="movetop" title="Go to top">
            &#10548;
        </button>
      </section>
      
        
    )
}
export default Footer
