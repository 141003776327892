import React from 'react' 
function Header(){
    return (        
                            
        <header className="w3l-header-nav">
	{/* <!--/nav--> */}
	<nav className="navbar navbar-expand-lg navbar-light fill px-lg-0 py-0 px-3">
		<div className="container">
			<a className="navbar-brand" href="/">Earthcon <span>India</span></a>
			{/* <!-- if logo is image enable this   --> */}
						{/* <a className="navbar-brand" href="#/">
							<img src="image-path" alt="Your logo" title="Your logo" style={{ height: "35px" }} />
						</a>  */}
			<button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="fa icon-expand fa-bars"></span>
				<span className="fa icon-close fa-times"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item active">
						<a className="nav-link" href="/">Home</a>
					</li>
					<li className="nav-item @@about__active">
						<a className="nav-link" href="/about">About</a>
					</li>
					<li className="nav-item @@services__active">
						<a className="nav-link" href="/our-products">Our Products</a>
					</li>
					<li className="nav-item @@services__active">
						<a className="nav-link" href="/services">Services</a>
					</li>
					<li className="nav-item @@services__active">
						<a className="nav-link" href="/gallery">Gallery</a>
					</li>
					<li className="nav-item @@contact__active">
						<a className="nav-link" href="/contact">Contact</a>
					</li>
                </ul>
                {/* <!--/search-right--> */}
				<div className="search-right">
					<a href="#search" title="search"><span className="fa fa-search" aria-hidden="true"></span></a>
					{/* <!-- search popup --> */}
					<div id="search" className="pop-overlay">
						<div className="popup">

							<form action="#" method="post" className="search-box">
								<input type="search" placeholder="Saerch your Keyword" name="search" required="required"
									autofocus=""/>
								<button type="submit" className="btn"><span className="fa fa-search" aria-hidden="true"></span></button>
							</form>

						</div>
						<a className="close" href="#close">×</a>
					</div>
					{/* <!-- /search popup --> */}
				</div>
				{/* <!--//search-right--> */}
				<div className="box-support">
					<p>For Support Call <span className="fa fa-headphones"></span></p>
					<h5><a href="tel:+919073678902">+91-9073678902</a></h5>
				</div>
			</div>
		</div>
	</nav>
	{/* <!--//nav--> */}
</header>
    )
}
export default Header
